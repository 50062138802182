import { GET_ALL_USERS_OWNER, GET_FILTERED_RISKS } from "../queries/risksQ";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { apolloClient, nhost, module } from "@/main";
import {
  SELECT_INITIAL_RISK,
  SELECT_CATEGORY,
  SELECT_EVALUATOR,
  SELECT_OWNER,
  SELECT_AREA_NOT_USER,
  SELECT_RESPONBILITIES,
  SELECT_USERS_BY_ROLE,
  SELECT_RESPONSIBLE,
  SELECT_TREATMENT_RESULT,
  TASK_OWNER,
  MODULES,
  SELECT_SUPPLIER,
  SELECT_STATUS,
  SELECT_RISK,
  SELECT_PARECER,
  SELECT_TYPOLOGY,
  SELECT_RISK_LEVEL,
  SELECT_VOLUME_HOLDERS,
  SELECT_PROFILE_HOLDERS,
  SELECT_DATA_TYPES,
  SELECT_LOCATION_HOLDERS,
  SELECT_AREA_NOT_IN_MODULE,
} from "@/api/queries/dropdowns_configQ";
import { SELECT_AREA, SELECT_AREA_ACTIVE } from "../queries/cad_areaQ";
import { SELECT_USER_TENANT_A } from "../queries/cad_user_modulesQ";
import { provideApolloClient } from "@vue/apollo-composable";
import { GET_CHOSEN_MODULES } from "../queries/associated-modulesQ";

const provideApollo = async (QUERY, params = {}) => {
  const { result, loading } = await provideApolloClient(apolloClient)(() =>
    useQuery(QUERY, params, {
      fetchPolicy: "no-cache",
    })
  );
  while (loading.value) {
    await new Promise((r) => setTimeout(r, 10));
  }
  return result;
};

export async function selectUserTenantA() {
  const tenant = nhost.auth.getHasuraClaim("x-hasura-Tenant-Now-Id");
  const result = await provideApollo(SELECT_USER_TENANT_A(), {
    id: tenant,
    module: module,
  });
  const data = result?.value?.users?.map((item: any) => ({
    value: { ...item },
    label: item?.email,
  }));
  return data;
}

export const getAllUsersOwner = async (id = null) => {
  const result = await provideApollo(GET_ALL_USERS_OWNER, { id });
  const data = result?.value?.user_module?.map((item: any) => ({
    value: item?.user.id,
    label: item?.user.displayName,
  }));
  return data;
};

export const getPriority = async (id = null) => {
  const result = await provideApollo(SELECT_INITIAL_RISK);
  const data = result?.value?.risk_management_risk?.map((item) => ({
    value: { ...item },
    label: item?.initial_risk,
  }));

  return data;
};

export const getTreatmentResult = async (id = null) => {
  const result = await provideApollo(SELECT_TREATMENT_RESULT);
  const data = result?.value?.risk_management_combo_data?.map((item) => ({
    value: { ...item },
    label: item?.value,
  }));

  return data;
};

export const getCategory = async (id = null) => {
  const result = await provideApollo(SELECT_CATEGORY);
  const data = result?.value?.risk_management_combo_data?.map((item) => ({
    value: { ...item },
    label: item?.value,
  }));

  return data;
};

export const getEvaluator = async (id = null) => {
  const result = await provideApollo(SELECT_EVALUATOR);
  const data = result?.value?.risk_management_risk?.map((item: any) => ({
    value: { ...item },
    label: item?.user_appraiser?.displayName,
  }));

  return data;
};

export const getOwner = async (id = null) => {
  const result = await provideApollo(SELECT_OWNER);
  const data = result?.value?.risk_management_risk?.map((item: any) => ({
    value: { ...item },
    label: item?.user_owner?.displayName,
  }));
  return data;
};

export const getAreaNotUser = async (id_user: string) => {
  const result = await provideApollo(SELECT_AREA_NOT_USER, { id_user, module });

  const data = result?.value?.area?.map((item: any) => ({
    value: { ...item },
    label: item?.description,
  }));
  return data;
};

export const getResponsibilities = async (id_user: string) => {
  const result = await provideApollo(SELECT_RESPONBILITIES, { id_user });
  const data = {
    divider_appraiser: result?.value?.divider_appraiser,
    divider_responsible: result?.value?.divider_responsible,
    divider_respondent: result?.value?.divider_respondent,
  };
  return data;
};

export const getUsersByRoleRisk = async (
  id_user: string = "",
  role: string = "",
  current_areas: number | string | null | number[] = null
) => {
  let areas: any = undefined;
  if (current_areas) {
    areas =
      typeof current_areas === "object"
        ? [...new Set(current_areas?.map((item: any) => item))]
        : [current_areas];
  }
  const conditions = areas
    ? areas.map(
        (area: string) =>
          `{user_areas: {id_area: {_eq: ${area}}, blocked: {_eq: false}}}`
      )
    : null;
  const result = await provideApollo(
    SELECT_USERS_BY_ROLE(id_user, role, conditions),
    { _eq: module }
  );

  const data = result?.value?.user_module?.map((item: any) => ({
    value: { ...item?.user },
    label: item?.user?.displayName,
  }));
  return data;
};

export const getResponsible = async (id = null) => {
  const result = await provideApollo(SELECT_RESPONSIBLE);
  const data = result?.value?.risk_management_risk?.map((item: any) => ({
    value: { ...item },
    label: item?.user_responsible?.displayName,
  }));
  return data;
};

export const getArea = async (id = null) => {
  const result = await provideApollo(SELECT_AREA_ACTIVE);

  const data = result?.value?.area?.map((item: any) => ({
    value: { ...item },
    label: item?.description,
  }));

  return data;
};

export const getTaskOwner = async (id = null) => {
  const result = await provideApollo(TASK_OWNER);
  const data = result?.value?.risk_management_risk_task?.map((item: any) => ({
    value: { ...item },
    label: item?.owner?.displayName,
  }));
  return data;
};

const filterOptions = (chosenModules, allOptions) => {
  const chosenModules_ = chosenModules.map(
    (chosen) => chosen.config_associated_module.tenant_module.module.module
  );

  return allOptions.filter(
    (option) =>
      !chosenModules_.includes(option.value.tenant_module.module.module)
  );
};

const formatLabel = (key) => {
  const labels = {
    due_diligence: "Due Diligence",
    data_mapping: "Mapeamento de Dados",
  };

  return labels[key] || key;
};

export const getModules = async (id_risk = null) => {
  const result = await provideApollo(MODULES);
  const allOptions =
    result?.value?.risk_management_config_associated_modules?.map(
      (item: any) => ({
        value: { ...item },
        label: formatLabel(item?.tenant_module?.module?.module),
      })
    );
  const chosenModules = await getChosenModules(id_risk);

  const optionsFiltered = filterOptions(chosenModules, allOptions);

  return optionsFiltered;
};

export const getChosenModules = async (id_risk = null) => {
  const result = await provideApollo(GET_CHOSEN_MODULES, { id_risk });
  const data = result?.value?.risk_management_risk_associated_modules;
  return data;
};

export const getSupplier = async (id = null) => {
  const result = await provideApollo(SELECT_SUPPLIER);
  const data = result?.value?.due_diligence_solicitation?.map((item: any) => ({
    value: { ...item },
    label: item?.supplier?.corporate_reason,
  }));
  return data;
};

export const getStatus = async (id = null) => {
  const result = await provideApollo(SELECT_STATUS);
  const data = result?.value?.due_diligence_solicitation?.map((item: any) => ({
    value: { ...item },
    label: item?.status,
  }));
  return data;
};

export const getRisk = async (id = null) => {
  const result = await provideApollo(SELECT_RISK);
  const data = result?.value?.due_diligence_solicitation?.map((item: any) => ({
    value: { ...item },
    label: item?.risk,
  }));
  return data;
};

export const getParecer = async (id = null) => {
  const result = await provideApollo(SELECT_PARECER);
  const data = result?.value?.due_diligence_solicitation?.map((item: any) => ({
    value: { ...item },
    label: item?.parecer_due_diligence,
  }));
  return data;
};

export const getTypology = async (id = null) => {
  const result = await provideApollo(SELECT_TYPOLOGY);
  const data = result?.value?.due_diligence_solicitation?.map((item: any) => ({
    value: { ...item },
    label: item?.typology?.description,
  }));
  return data;
};

export const getRiskLevel = async (id = null) => {
  const result = await provideApollo(SELECT_RISK_LEVEL);
  const data = result?.value?.data_mapping_combo_data?.map((item: any) => ({
    value: { ...item },
    label: item?.value,
  }));
  return data;
};

export const getVolumeHolders = async (id = null) => {
  const data = [
    { label: "0 - 50", value: "0 - 50" },
    { label: "51 - 100", value: "51 - 100" },
    { label: "101 - 200", value: "101 - 200" },
    { label: "201 - 300", value: "201 - 300" },
    { label: "301 - 400", value: "301 - 400" },
    { label: "401 - 500", value: "401 - 500" },
    { label: "Acima de 501", value: "Acima de 501" },
  ];
  return data;
};

export const getProfileHolders = async (id = null) => {
  const result = await provideApollo(SELECT_PROFILE_HOLDERS);
  const data = result?.value?.data_mapping_combo_data?.map((item: any) => ({
    value: { ...item },
    label: item?.value,
  }));
  return data;
};

export const getDataTypes = async (id = null) => {
  const result = await provideApollo(SELECT_DATA_TYPES);
  const data = result?.value?.data_mapping_combo_data?.map((item: any) => ({
    value: { ...item },
    label: item?.value,
  }));
  return data;
};

export const getlocationHolders = async (id = null) => {
  const result = await provideApollo(SELECT_LOCATION_HOLDERS);
  const data = result?.value?.data_mapping_combo_data?.map((item: any) => ({
    value: { ...item },
    label: item?.value,
  }));
  return data;
};

export const submitFormFilter = async (filters) => {
  try {
    const formatISODate = (dateStr, isEndOfDay = false) => {
      const [day, month, year] = dateStr.split("/");
      const time = isEndOfDay ? "23:59:59" : "00:00:00";
      const date = new Date(`${year}-${month}-${day}T${time}Z`);
      return date.toISOString();
    };

    const whereConditions = {
      _and: [],
    };

    // Adiciona os filtros ao whereConditions
    if (filters?.category?.value?.id) {
      whereConditions._and.push({
        category: {
          id: {
            _eq: filters.category.value.id,
          },
        },
      });
    }

    if (filters?.initialRisk?.label) {
      whereConditions._and.push({
        initial_risk: { _ilike: filters.initialRisk.label },
      });
    }

    if (filters?.treatment_result?.value?.id) {
      whereConditions._and.push({
        risk_monitoring: {
          id_treatment_result: {
            _eq: filters.treatment_result.value.id,
          },
        },
      });
    }

    if (filters?.residualRisk?.label) {
      whereConditions._and.push({
        risk_monitoring: {
          residual_risk: {
            _ilike: filters.residualRisk.label,
          },
        },
      });
    }

    if (filters?.evaluator?.value?.id) {
      whereConditions._and.push({
        _or: [
          {
            user_appraiser: {
              id: {
                _eq: filters.evaluator.value.id,
              },
            },
          },
          { user_appraiser: { id: { _is_null: true } } },
        ],
      });
    }

    if (filters?.startDate) {
      whereConditions._and.push({
        created_at: { _gte: formatISODate(filters.startDate) },
      });
    }

    if (filters?.endDate) {
      whereConditions._and.push({
        created_at: { _lte: formatISODate(filters.endDate, true) },
      });
    }

    if (filters?.startDateReview) {
      whereConditions._and.push({
        risk_monitoring: {
          review_deadline: {
            _gte: formatISODate(filters.startDateReview, true),
          },
        },
      });
    }

    if (filters?.endDateReview) {
      whereConditions._and.push({
        risk_monitoring: {
          review_deadline: {
            _lte: formatISODate(filters.endDateReview, true),
          },
        },
      });
    }

    if (filters?.startDateTreatment) {
      whereConditions._and.push({
        deadline_treatment: { _gte: formatISODate(filters.startDateTreatment) },
      });
    }

    if (filters?.endDateTreatment) {
      whereConditions._and.push({
        deadline_treatment: {
          _lte: formatISODate(filters.endDateTreatment, true),
        },
      });
    }

    // Verifica se status está presente
    if (filters?.status) {
      whereConditions._and.push({
        state: {
          _eq: filters.status,
        },
      });
    }

    // Verifica se risco está presente
    if (filters?.risk) {
      whereConditions._and.push({
        initial_risk: {
          _eq: filters.risk,
        },
      });
    }

    // Salvar o whereConditions no localStorage por usuário
    const userId = nhost.auth.getUser().id;
    const filtersKey = `whereConditions_${userId}`;
    localStorage.setItem(filtersKey, JSON.stringify(whereConditions));

    const variables = {
      where: whereConditions,
    };

    const { data } = await apolloClient.query({
      query: GET_FILTERED_RISKS,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.risk_management_risk;
  } catch (error) {
    console.error("Erro ao buscar os dados filtrados", error);
  }
};

export const getRiskTypes = async (id = null) => {
  const data = [
    { value: "1", label: "Alto" },
    { value: "2", label: "Médio" },
    { value: "3", label: "Baixo" },
  ];
  return data;
};

export const getRiskTypesResidual = async (id = null) => {
  const data = [
    { value: "1", label: "Alto" },
    { value: "2", label: "Médio" },
    { value: "3", label: "Baixo" },
  ];
  return data;
};

export const getAreaNotModule = async () => {
  const result = await provideApollo(SELECT_AREA_NOT_IN_MODULE, { module });
  const data = result?.value?.area?.map((item: any) => ({
    value: { ...item },
    label: item?.description,
  }));
  return data;
};
