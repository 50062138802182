<template>
  <Dialog
    v-model:visible="popupVisible"
    ref="valuesPopUp"
    :style="{ width: '710px', height: '592px' }"
    :modal="true"
    :class="`c-dialog dialog-historic ${
      popupDetailsVisible ? 'disable-close' : ''
    }`"
    :header="'Histórico'"
    :closable="true"
    :closeOnEscape="false"
    :draggable="false"
  >
    <main v-if="isLogsLoading.value" class="loading-container flex flex-col gap-[10px]">
     <component :is="Skeleton" v-for="iSkeleton in 6" :class="`!h-[70px] w-full`" :key="`skeleton-log-${iSkeleton}`"/>
    </main>
    <template #header v-if="popupDetailsVisible">
      <span class="p-dialog-title">Histórico</span>
      <div data-pc-section="icons">
        <component :is="Button"
          class="absolute top-4 right-0 mr-4 text-gray-400 hover:text-gray-800 outline-none"
          v-tooltip.bottom="`Voltar`"
          unstyled
          @click="popupDetailsVisible = false"
        >
          <component :is="IconX" v-if="!popupDetailsVisible" />
          <component :is="IconArrowLeftSquare" v-else class="hover:text-primary-second" />
        </component>
      </div>
    </template>
    <div v-if="!isLogsLoading.value" :class="`w-full flex flex-col min-h-80 ${!popupDetailsVisible && 'gap-[10px]'}`">
      <!--Exemplo das linhas-->
      <div v-for="(log, indexL) in logs_arranged.value" :key="`log-${indexL}`">

       <div
          v-if="!popupDetailsVisible"  
          class="flex justify-between items-center gap-8 w-full custom-card-border"
        >
          <div class="text-black font-normal">
            <p style="min-width: 333px; 
            max-width: 333px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;"
         >{{ log.user.displayName }} - {{ log.user.email }}</p>
            <p>{{ formatDatetime(log.created_at) }}</p>
          </div>
          <div class="flex items-center mr-2">
            <p class="w-24 m-0 font-semibold text-black">
              {{ log.action}}
            </p>
          </div>
            <Button class="p-1 btn-primary-second w-[28px] h-[28px]" @click="openDetails(log)">
              <component :is="IconSearch" class="w-5 h-5"/>
            </Button>
        </div>

        <!--Exemplo dos detalhes acionado pela lupa-->
        <div
          v-else-if="popupDetailsVisible && selected?.id == log?.id"
          class="border w-full border-white-second p-4 min-h-[512px]"
        >
          <div class="flex flex-col gap-6 w-full break-words overflow-hidden">
            <p class="m-0"><span class="font-semibold">Usuário</span>: {{ log.user.displayName }} - {{ log.user.email }}</p>
            <p class="m-0"><span class="font-semibold">Data</span>: {{ formatDatetime(log.created_at) }}</p>
            <p class="m-0"><span class="font-semibold">Ação</span>: {{ log.action }}</p>
          <div class="space-y-1 mt-4 flex flex-col whitespace-pr">
            <p class="font-semibold mb-4">Detalhes:</p>
            <div v-for="(field, i2) in log?.log_value?.rows" :key="`field-${i2}`" >
              <!--Ultilizar o v-for()-->   
                <span>{{ field }}</span>
            </div>
          </div>
          </div>

        </div>
      </div>
    </div>
  </Dialog>
</template>
<script>
import { ref } from "vue";
import ProgressSpinner from "primevue/progressspinner";
import Skeleton from "primevue/skeleton";
import IconSearch from "./icon/icon-search.vue";
import IconX from "./icon/icon-x.vue";
import IconArrowLeftSquare from "./icon/icon-arrow-left-square.vue";
import Button from 'primevue/button';

export default {
  name: "PopUpLog",
  setup() {
    return {
      Skeleton,
      ProgressSpinner,
      IconSearch,
      IconX,
      IconArrowLeftSquare,
      Button
    };
  },
  props: {
    getLogsManual: {
      type: Function,
      required: true,
    },
    table: {
      type: String,
      required: true,
    },
    schema: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    where: {
      type: Object,
      required: false,
      default: null
    },
  },
  data() {
    return {
      logs: ref([]),
      logs_arranged: ref([]),
      actions: { I: "Criado", U: "Editado" },
      isLogsLoading: ref([]),
      popupVisible: false,
      popupDetailsVisible: false,
      selected: {},
    };
  },
  watch: {
    async popupVisible(newPopupVisible) {
      if (!newPopupVisible) return;
      const { result, loading } = await this.getLogsManual({
        table: this.table,
        schema: this.schema,
        id: this.id, 
        where: this.where
      });
      this.logs.value = result;
      this.isLogsLoading.value = loading;
    },
    async "logs.value"(newLogs) {
      if (!newLogs?.audit_logged_actions_manual) return;
      this.logs_arranged.value = newLogs?.audit_logged_actions_manual.map((item) => {
        return {
          ...item,
        };
      });
    },
  },
  methods: {
    formatDatetime(datetimeString) {
      const datetime = new Date(datetimeString);

      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "America/Sao_Paulo",
      };

      const formatter = new Intl.DateTimeFormat("pt-BR", options);
      const formattedDateTime = formatter.format(datetime);

      return formattedDateTime.replace(",", " às");
    },
    openDetails(item = {}) {
      this.popupDetailsVisible = true;
      //adicionar o items a alguma variavel para ser utilizada no v-for dos details
      this.selected = item;
    },
  },
};
</script>